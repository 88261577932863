:root {
  --main-text-color: #283543;
}

.blockContainer {
  text-align: center;
}

#homeBlock {
  margin: 0 auto;
  height: 100vh;
  width: 100%;
}

.textHolder {
  color: var(--main-text-color);
  font-weight: 600;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}