:root {
  --color-primary: #e74a32;
  --color-blue: #3066be;
  --color-green: #63b133;
}

.mainContainer {
  padding: 20px;
  margin: 0 auto;
  max-width: 100%;
  transition: background-color 1.5s ease;
}

#homeBlock,
#aboutMeBlock,
#projectsBlock,
#contactBlock {
  min-height: 100vh; /* Each section takes up at least the full viewport height */
}

/* AboutMe slide-in animation */
#aboutMeBlock {
  position: relative;
  min-height: 100vh;
  transform: translateX(100%); /* Off-screen */
  transition: opacity 1s ease, transform 1s ease; /* Smooth sliding */
}

#aboutMeBlock.show {
  transform: translateX(-80%); /* Slide into view */
}