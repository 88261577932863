:root {
  --text-color: whitesmoke;
  --text-shadow: 2px 2px black;
  --background-stars: url("../../images/stars2.gif");
}

.aboutMe {
  background: var(--background-stars);
  background-size: cover;
  background-position: center;
  border-top-left-radius: 12%;
  border-bottom-left-radius: 12%;
  opacity: 0.8;
  transition: opacity 0.8s ease-in-out;
}

.hideStars {
  opacity: 0;
  transition: opacity 0.8s ease-in-out; /* Ensure fading out */
}

.text {
  opacity: 1;
}

.descriptionText {
  color: var(--text-color);
  width: 80%;
  font-weight: 560;
  font-size: 1.9vmax;
  position: relative;
  float: left;
  top: 6%;
  left: 10%;
  word-wrap: break-word;
  text-overflow: ellipsis;
  text-shadow: var(--text-shadow);
}