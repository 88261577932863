body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  height: 100vh;
  width: 100vw;
}
html{
  max-width: 100%;
  overflow-x: hidden;
}
