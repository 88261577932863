:root {
  --main-color: #283543;
  --hover-color-youtube: #BF2E25;
  --hover-color-soundcloud: #FE5419;
  --hover-color-linkedin: #006599;
  --hover-color-github: #FAFAFA;
}

#contactBlock {
  height: 100vh;
  width: 100vw;
}

#woodHolder {
  width: 100vw;
  height: 100vh;
  position: absolute;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

#woodBackground {
  width: 100vw;
  height: 12.5vh;
  background: url("../../images/wood.jpg") no-repeat center;
  background-size: cover;
}

#emailHolder {
  width: 100vw;
  height: 100vh;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

#email {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 12.5%;
  color: var(--main-color);
  font-weight: bold;
  font-size: 4em;
  font-size: 5.4vw;
  transition: transform 0.6s ease-in-out;
}

#email a {
  text-decoration: none;
  color: inherit;
}

#email:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.socialHolder {
  position: relative;
  top: 78%;
  left: 15%;
  width: 70%;
}

.social {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.icon {
  display: flex;
  color: var(--main-color);
  justify-content: center;
  align-items: center;
  height: 10vh;
  width: 10vh;
  border-radius: 13%;
  transition: background-color 500ms ease-in-out, transform 500ms ease-in-out;
}

.icon a {
  text-decoration: none;
  color: inherit;
}

.youtube:hover {
  background-color: var(--hover-color-youtube);
  transform: scale(1.1) translateY(-8px);
}

.soundcloud:hover {
  background-color: var(--hover-color-soundcloud);
  transform: scale(1.1) translateY(-8px);
}

.linkedin:hover {
  background-color: var(--hover-color-linkedin);
  transform: scale(1.1) translateY(-8px);
}

.github:hover {
  background-color: var(--hover-color-github);
  transform: scale(1.1) translateY(-8px);
}