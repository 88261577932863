.projNavItems {
  position: fixed;
  top: 50%;
  right: 1%;
  transform: translate(100%, -50%);
  transition: transform 1s ease-in-out;
}

.projNavItems.showProjBar {
  transform: translate(0, -50%);
}

.projNavItems.hideProjBar {
  transform: translate(100%, -50%);
}

.projNavItems li {
  cursor: pointer;
  list-style: none;
  font-size: 1.4vw;
  padding: 5px;
  color: white;
}

.projNavItems a {
  color: white;
  text-decoration: none;
}

.vr {
  border-left: 3px solid #fff;
  height: 120px;
}

.scrollButton {
  background: none;
  border: none;
  cursor: pointer;
  color: inherit;
}

.scrollButton:hover {
  transform: scale(1.1);
}