:root {
  --default-background: #222;
  --default-box-shadow: none;
  --text-color: white;
}

.projHolder {
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.projImageHolder {
  background-color: var(--default-background);
  height: 70%;
  width: 60%;
  border-radius: 12%;
  transition: background 1.5s ease-out, box-shadow 1.5s ease-out;
}

@media (max-width: 800px) {
  .projImageHolder {
    width: 80%;
    height: 60%;
    border-radius: 11%;
  }
}

@media (max-width: 550px) {
  .projImageHolder {
    width: 80%;
    height: 45%;
    border-radius: 11%;
  }
}

.vidWrapper {
  height: 86%;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.vidHolder {
  height: 80%;
  width: 99.5%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.vidText {
  text-align: center;
  color: var(--text-color);
  font-size: 3vw;
  margin-top: 1%;
}

#projectsBlock {
  margin: 0 auto;
  height: 400vh;
  width: 100vw;
}

.scrollButton {
  background: none;
  border: none;
  cursor: pointer;
  color: inherit;
}

.scrollButton:hover {
  transform: scale(1.1);
}