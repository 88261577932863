.burgerButton {
  position: fixed;
  width: 40px;
  height: 40px;
  cursor: pointer;
  z-index: 99;
  transition: all 500ms ease-in-out;
}

.bbLeft {
  top: 6%;
  left: 4%;
}

.burgerButton hr {
  height: 5px;
  background-color: white;
  border: 0;
  transition: all 500ms ease-in-out;
}

.burgerButton #topBBL {
  transform: rotate(45deg) translate(10px);
}

.burgerButton #middleBBL {
  opacity: 0;
}

.burgerButton #bottomBBL {
  transform: rotate(-45deg) translate(18px, -8px);
}

.burgerButton:hover {
  transform: scale(1.1);
}

.navItems {
  position: fixed;
  top: 0;
  left: 0;
  width: 20%;
  height: 100vh;
  z-index: 98;
  text-align: center;
  transition: transform 0.6s ease;
}

.hideBar {
  transform: translateX(-125%);
}

.navItems li {
  cursor: pointer;
  list-style: none;
  font-size: 1.4vw;
}

.navItems a {
  color: white;
  text-decoration: none;
}

.navigationContainer ul {
  margin: 0;
  padding-top: 15vh;
}

.navigationContainer ul a {
  margin-bottom: 10vh;
  width: 100%;
  font-size: 2em;
  display: block;
}

.navigationContainer ul a:hover {
  background: rgba(255, 255, 255, 0.2);
}